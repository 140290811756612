<template>
	<div>
		<QueryErrorNote v-if="store.lookupQueryFailed === true"></QueryErrorNote>
		<div v-else>
			<div class="selectbox-hold" style="margin-bottom: 20px;">
				<select v-model="chosenDemoId" class="selectbox">
					<option v-for="(dem, index) in demoSelectOptions" :key="index" :value="dem.id">{{dem.name}}</option>
				</select>
				<select v-model="viewType" class="selectbox">
					<option value="summary">Summary</option>
					<option value="market">Market</option>
					<option value="summaryTable">Summary Table</option>
				</select>
				<select v-if="viewType === 'market'" v-model="chosenMarketId" class="selectbox">
					<option v-for="mk in marketSelectOptions" :key="mk.id" :value="mk.id">{{mk.name}}</option>
				</select>
				<select v-model="chosenAudioType" class="selectbox">
					<option v-for="item in audioTypeOptions" :value="item.id" :key="item.id">{{item.name}}</option>
				</select>
				<select v-if="viewType !== 'summaryTable'" v-model="dataOrSpotsView" class="selectbox">
					<option value="data">Data View</option>
					<option value="spotslist">Spots List View</option>
				</select>
				<select v-model="genericOrActualWeek" class="selectbox">
					<option value="generic">Generic Weeks</option>
					<option value="actual">Actual Weeks</option>
					<option value="wcdate">Actual Dates</option>
					<option value="wcdatedmy">Actual Dates d/m/y</option>
				</select>
				<div v-if="store.shareableLinkSlug.length > 0" class="share-text" @click="showShareLinkPopup = true">
					<i class="mdi mdi-web-plus"></i>
					<span>Share Report</span>
				</div>
			</div>
			<ShareReportLink v-if="showShareLinkPopup" @close="showShareLinkPopup = false"></ShareReportLink>

			<div v-if="viewType === 'summaryTable'">
				<RFResultSummaryTable v-if="demoData" :demodata="demoData" :showactualweeks="genericOrActualWeek"></RFResultSummaryTable>
			</div>

			<div v-if="viewType === 'summary' || exportingToExcel === true" class="summary" id="summary">

				<div v-if="dataOrSpotsView === 'data' || exportingToExcel === true" class="topsection">
					<div class="overview-table">
						<table class="excel-table-info">
							<tbody>
							<tr>
								<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Overall Campaign Summary</td>
							</tr>
							<tr><td></td><td></td></tr>
							<tr v-if="exportingToExcel"><td>Demographic: </td><td>{{demoData.demoName}}</td></tr>
							<tr v-if="exportingToExcel"><td>Radio Type: </td><td>{{getAudioTypeNameFromId(demoData.audioType)}}</td></tr>
							<tr v-if="exportingToExcel"><td></td><td></td></tr>
							</tbody>
						</table>
						<RFSummaryTable v-if="demoData && demoData.summary" :summarydata="demoData.summary"></RFSummaryTable>
					</div>
					<div class="nplusSection">
						<div class="nplusTable">
							<RFNPlusExposureTable v-if="demoData.summary && demoData.summary.nPlusExposure" :tdata="demoData.summary.nPlusExposure"></RFNPlusExposureTable>
						</div>
						<div class="nplusChartHold">
							<p class="minor-heading">N+ Reach</p>
							<RFNPlusChart v-if="demoData && demoData.summary.nPlusExposure" :sourcedata="demoData.summary.nPlusExposure"></RFNPlusChart>
						</div>
					</div>
				</div>

				<div v-if="dataOrSpotsView === 'data' || exportingToExcel === true" class="halvedsections">
					<div>
						<table class="excel-table-info">
							<tbody>
							<tr>
								<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Splits By Market (Impacts)</td>
							</tr>
							<tr><td></td><td></td></tr>
							</tbody>
						</table>
						<div v-if="demoData && demoData.summary" class="rfMarketPieHold">
							<RFMarketPie :sourcedata="marketSummaryData"></RFMarketPie>
						</div>
						<div class="table-holder">
							<table v-if="demoData && demoData.summary" class="std-tbl">
								<thead>
								<tr>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Market</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Impacts</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Pct Impacts</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Pct of Cume</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
								</tr>
								</thead>
								<tbody>
								<tr v-for="mk in marketSummaryData" :key="mk.id">
									<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"><span class="clickable-text" @click="switchToMarketView(mk.id)">{{mk.name}}</span></td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.impacts}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.pctOfAllImpacts}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.reach}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.pctOfAllCume}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.avgFrequency}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.avgAud}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.cost}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{mk.cpm}}</td>
								</tr>
								</tbody>
							</table>
							<BlankExcelTwoRows></BlankExcelTwoRows>
						</div>

						<div class="table-holder">
							<table v-if="demoData && demoData.summary && chosenAudioType === 99" class="std-tbl" style="margin-top: 30px;">
								<thead>
								<tr>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Radio Type</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Impacts</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">GRPs</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cume %</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Frequency</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Avg Aud</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">Cost</td>
									<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">CPM</td>
								</tr>
								</thead>
								<tbody>
								<tr v-for="at in getAudioTypeSummaryData(this.chosenDemoId)" :key="at.id">
									<td data-t="s" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"><span class="clickable-text" @click="switchToMarketView(at.id)">{{at.name}}</span></td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.impacts}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.grossRating}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.reach}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.reachPct}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.avgFrequency}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.avgAud}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.cost}}</td>
									<td data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">{{at.cpm}}</td>
								</tr>
								</tbody>
							</table>
							<BlankExcelTwoRows></BlankExcelTwoRows>
						</div>
					</div>

					<div class="weeksplithold">
						<table class="excel-table-info">
							<tbody>
							<tr>
								<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Splits By Week</td>
							</tr>
							<tr><td></td><td></td></tr>
							</tbody>
						</table>

						<div v-if="demoData && demoData.weekSplits" style="width: 100%; height: 340px; margin-bottom: 20px;">
							<RFWeeklyCume :sourcedata="demoData.weekSplits" :showactualweeks="genericOrActualWeek"></RFWeeklyCume>
						</div>

						<div style="margin-bottom: 30px;" class="table-holder">
							<RFWeekSplitTable v-if="demoData && demoData.weekSplits" :weeksplitdata="demoData.weekSplits" :showactualweeks="genericOrActualWeek"></RFWeekSplitTable>
						</div>

						<div class="table-holder">
							<RFNetworkComboResultTable v-if="demoData && demoData.combos" :splitdata="demoData.combos"
								:marketid="0"
							></RFNetworkComboResultTable>
						</div>
					</div>
				</div>

				<div v-if="dataOrSpotsView === 'spotslist' || (exportingToExcel === true && this.store.rfConfig.schedule.length < this.store.doNotExportSpotsListInRFResultsIfMoreThanNSpots)" class="table-holder">
					<table class="excel-table-info">
						<tbody>
						<tr>
							<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="bold">Spot Schedule</td>
						</tr>
						<tr><td></td><td></td></tr>
						</tbody>
					</table>
					<div class="table-holder">
						<RFSpotScheduleTable v-if="demoData && demoData.schedule" :spotplays="demoData.schedule" :summary="true" :showactualweeks="genericOrActualWeek"></RFSpotScheduleTable>
					</div>
				</div>

				<WarningsBox v-if="computedWarnings && computedWarnings.length > 0" :warnings="computedWarnings"></WarningsBox>

			</div>

			<div v-if="(viewType === 'market' || exportingToExcel === true)">
				<div v-for="mk in demoData.marketSplits" :key="mk.marketId" :id="'marketInfo_'+mk.marketName" class="marketInfo" :class="{hidden : chosenMarketId !== mk.marketId && !exportingToExcel}">
					<RFMarketInfoPage :demodata="demoData" :chosen_market_id="mk.marketId"
						:dataorspotsview="dataOrSpotsView" :exportingtoexcel="exportingToExcel"
						:audiotypesummarydata="getMarketAudioTypeSummaryData(this.chosenDemoId, mk.marketId)"
						:showactualweeks="genericOrActualWeek"
					></RFMarketInfoPage>
				</div>
			</div>

			<div v-if="viewType !== 'summaryTable'" class="primary-button" @click="exportAllToExcelNew()" style="margin-top: 20px;">
				<i class="mdi mdi-file-excel-outline"></i>
				Export To Excel
			</div>

<!--			<div v-if="viewType === 'summary'" class="primary-button" @click="exportRfOutCsv" style="margin-left: 20px;">-->
<!--				<i class="mdi mdi-file-excel"></i>-->
<!--				Export RFOUT CSV (GFK Only)-->
<!--			</div>-->

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import RFWeekSplitTable from "@/components/result-tables/RFWeekSplitTable";
import RFSpotScheduleTable from "@/components/result-tables/RFSpotScheduleTable";
import RFSummaryTable from "@/components/result-tables/RFSummaryTable";
import WarningsBox from "@/components/result-tables/WarningsBox";
import RFNPlusExposureTable from "@/components/result-tables/RFNPlusExposureTable";
import TableToExcel from "@linways/table-to-excel";
import BlankExcelTwoRows from "@/components/result-tables/BlankExcelTwoRows";
import RFMarketInfoPage from "@/components/result-tables/RFMarketInfoPage";
import RFNPlusChart from "@/components/rfcharts/RFNPlusChart.vue";
import RFMarketPie from "@/components/rfcharts/RFMarketPie.vue";
import RFWeeklyCume from "@/components/rfcharts/RFWeeklyCume.vue";
import QueryErrorNote from "@/components/QueryErrorNote.vue";
import RFResultSummaryTable from "@/components/rf/RFResultSummaryTable.vue";
import Exceljs from "exceljs";
import ShareReportLink from "@/components/overlays/ShareReportLink.vue";
import RFNetworkComboResultTable from "@/components/result-tables/RFNetworkComboResultTable.vue";

export default {
	name: "RFResultContentPage",
	components: {
		ShareReportLink,
		RFNetworkComboResultTable,
		RFResultSummaryTable,
		QueryErrorNote,
		RFWeeklyCume,
		RFMarketPie,
		RFNPlusChart,
		RFMarketInfoPage,
		BlankExcelTwoRows,
		RFNPlusExposureTable,
		WarningsBox,
		RFSummaryTable,
		RFSpotScheduleTable,
		RFWeekSplitTable
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			chosenDemoId: null,
			viewType: 'summary', //summary, market, summaryTable
			chosenMarketId: null, //only used if viewType == market
			chosenAudioType: 99,
			dataOrSpotsView: 'data', //data, spotslist
			genericOrActualWeek: 'generic', //generic, actual, wcdate, wcdatedmy

			exportingToExcel: false,
			exportDemoCount: 0,
			exportDemoCountTotal: 0,
			excelbook: null,

			showShareLinkPopup: false,

		}
	},
	computed: {
		marketSelectOptions() {
			if(this.demoData) {
				let everythingDD = this.store.returnedData.find(item => item.audioType === 99)
				if (everythingDD) {
					let arr = []
					for (let mk of everythingDD.marketSplits) {
						arr.push({id: mk.marketId, name: mk.marketName})
					}
					return arr
				}
			}
			return []
		},
		demoSelectOptions() {
			let demOpts = []
			let demids = []
			for(let it of this.store.returnedData) {
				if(demids.includes(it.demoId)) continue
				demOpts.push({id: it.demoId, name: it.demoName})
				demids.push(it.demoId)
			}
			return demOpts
		},
		audioTypeOptions() {
			let arr = [
				{id: 99, name: 'Everything'},
				{id: 0, name: 'Total Radio Only'},
				{id: 1, name: 'AM/FM/DAB+ Only'},
				{id: 2, name: 'Streaming Only'},
			]
			let arrTotOnly = [{id: 99, name: 'Everything'}]
			if(this.marketCanSplitAudioTypes === true) return arr
			return arrTotOnly
		},
		marketCanSplitAudioTypes() {
			if(!this.store.returnedData.find(item => item.audioType === 1)) {
				return false
			}
			if(this.chosenMarketId === null || this.chosenMarketId === 0) return true
			else if(this.chosenMarketId !== null && this.chosenMarketId > 0) {
				let mkob = this.store.markets.find(item => item.id === this.chosenMarketId)
				if(mkob && mkob.isGfk === true) return true
			}
			return false
		},
		demoData() {
			if(this.chosenDemoId) {
				if(this.store.returnedData.find(item => item.demoId === this.chosenDemoId && item.audioType === this.chosenAudioType)) {
					return this.store.returnedData.find(item => item.demoId === this.chosenDemoId && item.audioType === this.chosenAudioType)
				}
			}
			return false
		},
		marketSummaryData() {
			let totalImpacts = this.demoData.summary.impacts
			let totalCume = this.demoData.summary.reach
			let arr = []
			for(let mk of this.demoData.marketSplits) {
				arr.push({
					id: mk.marketId,
					name: mk.marketName,
					impacts: this.numdisplay(mk.impacts),
					pctOfAllImpacts: this.numdisplayNdp(mk.impacts / totalImpacts * 100, 1),
					reach: this.numdisplay(mk.reach),
					pctOfAllCume: this.numdisplayNdp(mk.reach / totalCume * 100, 1),
					cpm: mk.cpm,
					cost: this.numdisplay(mk.cost),
					avgFrequency: mk.avgFrequency,
					avgAud: this.numdisplay(mk.avgAud),
				})
			}
			return arr
		},
		hasMetroAndRegionalCombination() {
			let hasMetro = false //SMBAP only - not GC, NEW, CBR
			let hasRegional = false
			for(let m of this.store.selectionObs.markets) {
				if(m.isGfk === true && this.store.gfkMetroMktIds.includes(m.id)) hasMetro = true
				else hasRegional = true
			}
			if(hasMetro === true && hasRegional === true) return true
			return false
		},
		computedWarnings() {
			let arr = []
			if(this.demoData && this.demoData.warnings && this.demoData.warnings.length > 0) {
				arr = this.demoData.warnings
			}
			if(this.hasMetroAndRegionalCombination) {
				arr.push('Grouped Markets Summary should be viewed with caution when GFK Metro markets are run with GFK Regional or Xtra Regional markets.  Summaries are provided for efficiency purposes only.')
			}
			if(this.demoData && this.demoData.weekSplits && this.demoData.weekSplits.length > 12) {
				arr.push('The CRA R&F engine is optimised for up to 12 week campaigns. Campaigns beyond 12 weeks should be read with a degree of caution.')
			}
			return arr
		},
	},
	methods: {
		getAudioTypeSummaryData(demoId) {
			let arr = []
			let atkeys = [0,1,2]
			for(let i of atkeys) {
				if(this.store.returnedData.find(item => item.demoId === demoId && item.audioType === i)) {
					let d = this.store.returnedData.find(item => item.demoId === demoId && item.audioType === i)
					arr.push({
						id: i,
						name: this.getAudioTypeNameFromId(i, true),
						impacts: this.numdisplay(d.summary.impacts),
						reach: this.numdisplay(d.summary.reach),
						reachPct: this.numdisplayNdp(d.summary.reachPct,1),
						cpm: d.summary.cpm,
						cost: this.numdisplay(d.summary.cost),
						avgFrequency: d.summary.avgFrequency,
						avgAud: this.numdisplay(d.summary.avgAud),
						grossRating: this.numdisplayNdp(d.summary.grossRating,1),
					})
				}
			}
			return arr
		},
		getMarketAudioTypeSummaryData(demoId, marketId) {
			let arr = []
			for(let i=0; i<=2; i++) {
				if(this.store.returnedData.find(item => item.demoId === demoId && item.audioType === i)) {
					let dd = this.store.returnedData.find(item => item.demoId === demoId && item.audioType === i)
					let d = dd.marketSplits.find(item => item.marketId === marketId)
					if(d) {
						arr.push({
							id: i,
							name: this.getAudioTypeNameFromId(i),
							impacts: this.numdisplay(d.impacts),
							reach: this.numdisplay(d.reach),
							reachPct: this.numdisplayNdp(d.reachPct, 1),
							cpm: d.cpm,
							cost: this.numdisplay(d.cost),
							avgFrequency: d.avgFrequency,
							avgAud: this.numdisplay(d.avgAud),
							grossRating: this.numdisplayNdp(d.grossRating, 1),
						})
					}
				}
			}
			return arr
		},
		exportAllToExcelNew() {
			this.store.showLoader = true
			const workbook = new Exceljs.Workbook()
			let sheetcount = 1
			for(let demoData of this.store.returnedData) { //one each of demo/radiotype
				let loopData = [
					{d: demoData.summary, isSummary: true}
				]
				for(let md of demoData.marketSplits) {
					loopData.push({d: md, isSummary: false})
				}
				for(let loopItem of loopData) {
					let mydata = loopItem.d
					let worksheet = workbook.addWorksheet('Sheet '+sheetcount)
					let mktname = 'Summary (Grouped Total)'
					if(loopItem.isSummary === false) mktname = this.getMarketNameFromId(mydata.marketId)
					let worksheetData = [
						[{t: 'Overall Campaign Summary', s: this.store.xlfmt.header}],
						[],
						[{t: 'Demographic: ' + demoData.demoName}],
						[{t: 'Radio Type: '+ this.getAudioTypeNameFromId(demoData.audioType)}],
						[{t: 'Market: '+ mktname}],
						[],
						[{t: 'Spots', s: this.store.xlfmt.boldborder}, {t: mydata.nSpots, s: this.store.xlfmt.numberborder}],
						[{t: 'Impacts', s: this.store.xlfmt.boldborder}, {t: mydata.impacts, s: this.store.xlfmt.numberborder}],
						[{t: 'Gross Rating Pct', s: this.store.xlfmt.boldborder}, {t: this.flround(mydata.grossRating, 1), s: this.store.xlfmt.numberborder}],
						[{t: 'Cume000s Total Reach', s: this.store.xlfmt.boldborder}, {t: this.flround(mydata.reach, 0), s: this.store.xlfmt.numberborder}],
						[{t: 'CumePct Total Reach Pct', s: this.store.xlfmt.boldborder}, {t: this.flround(mydata.reachPct, 1), s: this.store.xlfmt.numberborder}],
						[{t: 'Average Frequency', s: this.store.xlfmt.boldborder}, {t: this.flround(mydata.avgFrequency, 1), s: this.store.xlfmt.numberborder}],
						[{t: 'Average Audience', s: this.store.xlfmt.boldborder}, {t: this.flround(mydata.avgAud, 0), s: this.store.xlfmt.numberborder}],
						[{t: 'Universe Estimate', s: this.store.xlfmt.boldborder}, {t: this.flround(mydata.universeEstimate, 0), s: this.store.xlfmt.numberborder}],
						[{t: 'Total Cost', s: this.store.xlfmt.boldborder}, {t: this.flround(mydata.cost, 0), s: this.store.xlfmt.numberborder}],
						[{t: 'CPM', s: this.store.xlfmt.boldborder}, {t: this.flround(mydata.cpm, 2), s: this.store.xlfmt.numberborder}],
						[],
						[],
						[{t: 'N+ Spots Exposure', s: this.store.xlfmt.header}],
						[],
						[
							{t: 'At Least N Spots', s: this.store.xlfmt.borderthead},
							{t: 'Excl %', s: this.store.xlfmt.borderthead},
							{t: 'n+ %', s: this.store.xlfmt.borderthead},
							{t: 'Excl Reach', s: this.store.xlfmt.borderthead},
							{t: 'n+ Reach', s: this.store.xlfmt.borderthead},
						],
					]
					for(let mk of mydata.nPlusExposure) {
						worksheetData.push([
							{t: mk.spot+'+', s: this.store.xlfmt.textborder},
							{t: this.flround(mk.exclPct, 1), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.nPlusPct, 1), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.exclReach, 0), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.nPlusReach, 0), s: this.store.xlfmt.numberborder},
						])
					}
					if(loopItem.isSummary === false) { //market pages
						worksheetData.push([], [{t: 'Splits By Station', s: this.store.xlfmt.header}], [])
						worksheetData.push([
							{t: 'Station', s: this.store.xlfmt.borderthead},
							{t: 'N Spots', s: this.store.xlfmt.borderthead},
							{t: 'Impacts', s: this.store.xlfmt.borderthead},
							{t: 'GRPs', s: this.store.xlfmt.borderthead},
							{t: 'Cume', s: this.store.xlfmt.borderthead},
							{t: 'Cume %', s: this.store.xlfmt.borderthead},
							{t: 'Avg Frequency', s: this.store.xlfmt.borderthead},
							{t: 'Avg Aud', s: this.store.xlfmt.borderthead},
							{t: 'Cost', s: this.store.xlfmt.borderthead},
							{t: 'CPM', s: this.store.xlfmt.borderthead},
						])
						for (let mk of demoData.stationResults) {
							if(mk.marketId === mydata.marketId) {
								worksheetData.push([
									{t: this.getStationNameFromId(mk.stationId), s: this.store.xlfmt.textborder},
									{t: this.flround(mk.nSpots), s: this.store.xlfmt.numberborder},
									{t: this.flround(mk.impacts), s: this.store.xlfmt.numberborder},
									{t: this.flround(mk.grossRating, 1), s: this.store.xlfmt.numberborder},
									{t: this.flround(mk.reach), s: this.store.xlfmt.numberborder},
									{t: this.flround(mk.reachPct, 1), s: this.store.xlfmt.numberborder},
									{t: this.flround(mk.avgFrequency, 1), s: this.store.xlfmt.numberborder},
									{t: this.flround(mk.avgAud), s: this.store.xlfmt.numberborder},
									{t: this.flround(mk.cost), s: this.store.xlfmt.numberborder},
									{t: this.flround(mk.cpm, 2), s: this.store.xlfmt.numberborder},
								])
							}
						}
						//splits by station/week
						worksheetData.push([],[])
						worksheetData.push([
							{t: 'Station', s: this.store.xlfmt.borderthead},
							{t: 'Week Number', s: this.store.xlfmt.borderthead},
							{t: 'N Spots', s: this.store.xlfmt.borderthead},
							{t: 'Impacts', s: this.store.xlfmt.borderthead},
							{t: 'GRPs', s: this.store.xlfmt.borderthead},
							{t: 'Cume', s: this.store.xlfmt.borderthead},
							{t: 'Cume %', s: this.store.xlfmt.borderthead},
							{t: 'Avg Frequency', s: this.store.xlfmt.borderthead},
							{t: 'Avg Aud', s: this.store.xlfmt.borderthead},
							{t: 'Cost', s: this.store.xlfmt.borderthead},
							{t: 'CPM', s: this.store.xlfmt.borderthead},
						])
						for (let mk of demoData.stationResults) {
							if (mk.marketId === mydata.marketId) {
								for(let wk of mk.weekSplits) {
									let wn = wk.weekNumber
									if(this.genericOrActualWeek === 'actual') wn = wk.actualWeekId
									else if(this.genericOrActualWeek === 'wcdate') wn = wk.actualWeekStartDate
									else if(this.genericOrActualWeek === 'wcdatedmy') wn = this.convertYMDtoDMY(wk.actualWeekStartDate)
									worksheetData.push([
										{t: this.getStationNameFromId(mk.stationId), s: this.store.xlfmt.textborder},
										{t: wn, s: this.store.xlfmt.textborder},
										{t: this.flround(wk.nSpots), s: this.store.xlfmt.numberborder},
										{t: this.flround(wk.impacts), s: this.store.xlfmt.numberborder},
										{t: this.flround(wk.grossRating, 1), s: this.store.xlfmt.numberborder},
										{t: this.flround(wk.reach), s: this.store.xlfmt.numberborder},
										{t: this.flround(wk.reachPct, 1), s: this.store.xlfmt.numberborder},
										{t: this.flround(wk.avgFrequency, 1), s: this.store.xlfmt.numberborder},
										{t: this.flround(wk.avgAud), s: this.store.xlfmt.numberborder},
										{t: this.flround(wk.cost), s: this.store.xlfmt.numberborder},
										{t: this.flround(wk.cpm, 2), s: this.store.xlfmt.numberborder},
									])
								}
							}
						}
					}
					else { //summary page
						worksheetData.push([], [{t: 'Splits By Market', s: this.store.xlfmt.header}], [])
						worksheetData.push([
							{t: 'Market', s: this.store.xlfmt.borderthead},
							{t: 'Impacts', s: this.store.xlfmt.borderthead},
							{t: 'Pct Impacts', s: this.store.xlfmt.borderthead},
							{t: 'Cume', s: this.store.xlfmt.borderthead},
							{t: 'Pct of Cume', s: this.store.xlfmt.borderthead},
							{t: 'Avg Frequency', s: this.store.xlfmt.borderthead},
							{t: 'Avg Aud', s: this.store.xlfmt.borderthead},
							{t: 'Cost', s: this.store.xlfmt.borderthead},
							{t: 'CPM', s: this.store.xlfmt.borderthead},
						])
						for (let mk of demoData.marketSplits) {
							let pctOfAllImpacts = mk.impacts / mydata.impacts * 100
							let pctOfAllCume = mk.reach / mydata.reach * 100
							worksheetData.push([
								{t: mk.marketName, s: this.store.xlfmt.textborder},
								{t: this.flround(mk.impacts), s: this.store.xlfmt.numberborder},
								{t: this.flround(pctOfAllImpacts, 1), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.reach), s: this.store.xlfmt.numberborder},
								{t: this.flround(pctOfAllCume, 1), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.avgFrequency, 1), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.avgAud), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.cost), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.cpm, 2), s: this.store.xlfmt.numberborder},
							])
						}
					}

					//radio types - only show for Everything Combined page
					if(demoData.audioType === 99) {
						worksheetData.push([], [])
						worksheetData.push([
							{t: 'Radio Type', s: this.store.xlfmt.borderthead},
							{t: 'Impacts', s: this.store.xlfmt.borderthead},
							{t: 'Pct Impacts', s: this.store.xlfmt.borderthead},
							{t: 'Cume', s: this.store.xlfmt.borderthead},
							{t: 'Pct of Cume', s: this.store.xlfmt.borderthead},
							{t: 'Avg Frequency', s: this.store.xlfmt.borderthead},
							{t: 'Avg Aud', s: this.store.xlfmt.borderthead},
							{t: 'Cost', s: this.store.xlfmt.borderthead},
							{t: 'CPM', s: this.store.xlfmt.borderthead},
						])
						let myaudiotypedata = []
						if(loopItem.isSummary === false) {
							myaudiotypedata = this.getMarketAudioTypeSummaryData(demoData.demoId, mydata.marketId)
						}
						else { //summary
							myaudiotypedata = this.getAudioTypeSummaryData(demoData.demoId)
						}
						for (let mk of myaudiotypedata) {
							worksheetData.push([
								{t: mk.name, s: this.store.xlfmt.textborder},
								{t: this.flround(mk.impacts), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.grossRating, 1), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.reach), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.reachPct, 1), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.avgFrequency, 1), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.avgAud), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.cost), s: this.store.xlfmt.numberborder},
								{t: this.flround(mk.cpm, 2), s: this.store.xlfmt.numberborder},
							])
						}
					}

					worksheetData.push([],[],[{t: 'Splits By Week', s: this.store.xlfmt.header}],[])
					worksheetData.push([
						{t: 'Week Number', s: this.store.xlfmt.borderthead},
						{t: 'N Spots', s: this.store.xlfmt.borderthead},
						{t: 'Impacts', s: this.store.xlfmt.borderthead},
						{t: 'Reach (Cumulative)', s: this.store.xlfmt.borderthead},
						{t: 'Avg Frequency', s: this.store.xlfmt.borderthead},
						{t: 'Avg Aud', s: this.store.xlfmt.borderthead},
						{t: 'Cost', s: this.store.xlfmt.borderthead},
						{t: 'CPM', s: this.store.xlfmt.borderthead},
					])
					let wkdata = demoData.weekSplits
					if(loopItem.isSummary === false) wkdata = mydata.weekSplits
					for(let mk of wkdata) {
						let wn = 'W1 - '+mk.weekNumber
						if(this.genericOrActualWeek === 'actual') {
							wn = wkdata[0].actualWeekId + ' to '+ mk.actualWeekId
						}
						else if(this.genericOrActualWeek === 'wcdate') {
							wn = wkdata[0].actualWeekStartDate+' to '+mk.actualWeekStartDate
						}
						else if(this.genericOrActualWeek === 'wcdatedmy') {
							wn = this.convertYMDtoDMY(wkdata[0].actualWeekStartDate)+' to '+this.convertYMDtoDMY(mk.actualWeekStartDate)
						}
						worksheetData.push([
							{t: wn, s: this.store.xlfmt.textborder},
							{t: this.flround(mk.nSpotsCumulative), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.impactsCumulative), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.reachCumulative), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.avgFrequencyCumulative,1), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.avgAudCumulative), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.costCumulative), s: this.store.xlfmt.numberborder},
							{t: this.flround(mk.cpmCumulative, 2), s: this.store.xlfmt.numberborder},
						])
					}

					//spot schedule list
					worksheetData.push([],[],[{t: 'Spot Schedule', s: this.store.xlfmt.header}],[])
					let myline = [
						{t: 'Spot Number', s: this.store.xlfmt.borderthead},
						{t: 'Week Number', s: this.store.xlfmt.borderthead},
						{t: 'Market', s: this.store.xlfmt.borderthead},
						{t: 'Play Date', s: this.store.xlfmt.borderthead},
						{t: 'Station', s: this.store.xlfmt.borderthead},
						{t: 'Radio Type', s: this.store.xlfmt.borderthead},
						{t: 'Play Time', s: this.store.xlfmt.borderthead},
						{t: 'Spot Type', s: this.store.xlfmt.borderthead},
						{t: 'Reach / Impacts', s: this.store.xlfmt.borderthead},
					]
					if(!loopItem.isSummary) myline.push({t: 'Rtg %', s: this.store.xlfmt.borderthead})
					if(!loopItem.isSummary) myline.push({t: 'GRP %', s: this.store.xlfmt.borderthead})
					myline.push({t: 'Cost', s: this.store.xlfmt.borderthead})
					myline.push({t: 'CPM', s: this.store.xlfmt.borderthead})
					worksheetData.push(myline)

					let spind = 0
					let sched = this.demoData.schedule
					if(loopItem.isSummary === false) sched = mydata.schedule
					for(let sp of sched) {
						let pldate = null
						if(sp.playDate && sp.playDate.length) pldate = sp.playDate
						else if(sp.dayOfWeek && sp.dayOfWeek.length) pldate = this.ucfirst(sp.dayOfWeek)
						let wn = sp.weekNumber
						if(this.genericOrActualWeek === 'actual') wn = sp.actualWeekId
						else if(this.genericOrActualWeek === 'wcdate') wn = sp.actualWeekStartDate
						else if(this.genericOrActualWeek === 'wcdatedmy') wn = this.convertYMDtoDMY(sp.actualWeekStartDate)
						let myline = [
							{t: spind+1, s: this.store.xlfmt.numberborder},
							{t: wn, s: this.store.xlfmt.textborder},
							{t: this.getMarketNameFromId(sp.marketId), s: this.store.xlfmt.textborder},
							{t: pldate, s: this.store.xlfmt.textborder},
							{t: sp.stationName, s: this.store.xlfmt.textborder},
							{t: this.getAudioTypeNameFromId(sp.audioType), s: this.store.xlfmt.textborder},
							{t: sp.playTime, s: this.store.xlfmt.textborder},
							{t: sp.spotClass, s: this.store.xlfmt.textborder},
							{t: this.flround(sp.reach), s: this.store.xlfmt.textborder},
						]
						if(!loopItem.isSummary) myline.push({t: this.flround(sp.reachPct, 1), s: this.store.xlfmt.numberborder})
						if(!loopItem.isSummary) myline.push({t: this.flround(sp.cumulativeGrpPct, 1), s: this.store.xlfmt.numberborder})
						myline.push({t: this.flround(sp.spotRate, 2), s: this.store.xlfmt.numberborder})
						myline.push({t: this.flround(sp.cpm, 2), s: this.store.xlfmt.numberborder})
						worksheetData.push(myline)
						spind++
					}

					//write to the sheet
					for(let rowInt=1; rowInt<=worksheetData.length; rowInt++) {
						for(let colInt=1; colInt<=worksheetData[rowInt-1].length; colInt++) {
							worksheet.getCell(rowInt, colInt).value = worksheetData[rowInt-1][colInt-1].t
							if(worksheetData[rowInt-1][colInt-1].s) {
								if(worksheetData[rowInt-1][colInt-1].s.font) {
									worksheet.getCell(rowInt, colInt).font = worksheetData[rowInt-1][colInt-1].s.font
								}
								else {
									worksheet.getCell(rowInt, colInt).font = {name: 'Tahoma', size: 10}
								}
								if(worksheetData[rowInt-1][colInt-1].s.fill) {
									worksheet.getCell(rowInt, colInt).fill = worksheetData[rowInt-1][colInt-1].s.fill
								}
								if(worksheetData[rowInt-1][colInt-1].s.border) {
									worksheet.getCell(rowInt, colInt).border = worksheetData[rowInt-1][colInt-1].s.border
								}
								if(worksheetData[rowInt-1][colInt-1].s.numFmt) {
									worksheet.getCell(rowInt, colInt).numFmt = worksheetData[rowInt-1][colInt-1].s.numFmt
								}
							}
							else {
								worksheet.getCell(rowInt, colInt).font = {name: 'Tahoma', size: 10}
							}
						}

					}
					sheetcount++
				}
			}

			//write to file and download
			workbook.xlsx.writeBuffer().then((data) => {
				const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url
				a.download = 'Audology RF Export.xlsx'
				a.click()
				window.URL.revokeObjectURL(url)
			})
			this.store.showLoader = false
		},
		// exportAllToExcel() {
		// 	this.exportingToExcel = true
		// 	this.exportDemoCount = 0
		// 	this.exportDemoCountTotal = this.store.returnedData.length
		// 	this.store.showLoader = true
		// 	this.excelbook = null
		// 	let sheetCount = 1
		// 	let self = this
		// 	let promise = Promise.resolve()
		// 	let interval = 500
		// 	setTimeout(function() { //wait for this.exportingToExcel to be activated
		// 		self.store.returnedData.forEach(function(demdata) {
		// 			promise = promise.then(function() {
		// 				self.chosenAudioType = demdata.audioType
		// 				self.chosenDemoId = demdata.demoId
		// 				setTimeout(function() {
		// 					// let demoname = demdata.demoName
		// 					// let audioType = demdata.audioType
		// 					let content = document.querySelector("#summary")
		// 					let contentT = content.cloneNode(true)
		// 					contentT.innerHTML = contentT.innerHTML.replace(/,/g, ''); //replaces , globally
		// 					let thisSheetName = 'Sheet ' + sheetCount //demoname+' Summary'
		// 					sheetCount++
		// 					//if(audioType > 0) thisSheetName += ' '+audioType
		// 					if (self.excelbook === null) {
		// 						self.excelbook = TableToExcel.tableToBook(contentT, {sheet: {name: thisSheetName}})
		// 					}
		// 					else {
		// 						TableToExcel.tableToSheet(self.excelbook, contentT, {sheet: {name: thisSheetName}})
		// 					}
		// 					let count = 1
		// 					for (let item of document.querySelectorAll(".marketInfo")) {
		// 						let table2 = item.cloneNode(true)
		// 						table2.innerHTML = table2.innerHTML.replace(/,/g, '')
		// 						let name = item.id
		// 						name = name.replace("marketInfo_", "")
		// 						if (name.length === 0) name = 'Market' + count
		// 						thisSheetName = 'Sheet ' + sheetCount // demoname+' '+name
		// 						sheetCount++
		// 						//if(audioType > 0) thisSheetName += ' '+audioType
		// 						TableToExcel.tableToSheet(self.excelbook, table2, {sheet: {name: thisSheetName}})
		// 						count++
		// 					}
		// 					self.exportDemoCount++
		// 				}, 200)
		// 				return new Promise(function (resolve) {
		// 					setTimeout(resolve, interval);
		// 				});
		// 			})
		// 		})
		// 		promise.then(function () {
		// 			self.store.showLoader = false
		// 		});
		// 	}.bind(this), 500)
		// },
		doExcelWrite() {
			if(this.exportingToExcel === true && this.exportDemoCountTotal > 0) {
				TableToExcel.save(this.excelbook, "Audology RF Export.xlsx")
				this.exportingToExcel = false
				this.store.showLoader = false
				this.exportDemoCount = 0
			}
		},
		selectFirstIfNull() {
			if(this.store.returnedData.length > 0 && !this.chosenDemoId) {
				this.chosenDemoId = this.store.returnedData[0].demoId
			}
		},
		switchToMarketView(mktid) {
			this.chosenMarketId = mktid
			this.viewType = 'market'
			window.scroll(0,0)
		},
		// exportRfOutCsv() {
		// 	let str = "FileType,Version,ScheduleCreateDate,SoftwareTag,Survey,MarketId,MarketDesc,SpotNumber,SpotDate,"
		// 	str += "SpotTime,SpotDuration,StationId,StationShortDesc,AudioTypeId,Cost,SpotType,ScheduleDesc,"
		// 	str += "Demographic,DemographicDesc,UniverseEstimate,AvAudience,SpotReach,CumeReach,"
		// 	for(let i=1; i<=10; i++) {
		// 		let myi = i
		// 		if(i<10) myi = '0'+i
		// 		str += "Cume"+myi+"Aud,"
		// 	}
		// 	str += "\n"
		// 	let spotNumber = 0
		// 	let createDate = this.getTodayRFCSVDate()
		// 	for(let s of this.demoData.schedule) {
		// 		if(s.stationId.indexOf('g_') > -1) {
		// 			let survid = this.getRFSurveyCodeForSpotRow(s)
		// 			let survob = this.store.surveys.find(item => item.id === survid)
		// 			let stnob = this.store.stations.find(item => item.id === s.stationId)
		// 			let mktid = stnob.mktid
		// 			let survFieldWorkStart = null
		// 			if(survob) {
		// 				survFieldWorkStart = survob.fieldwork_start //YYYY-MM-DD - always a Sunday
		// 			}
		// 			let mktob = this.store.markets.find(item => item.id === mktid)
		// 			let playDate = '';
		// 			if(s.playDate) playDate = s.playDate
		// 			else if(s.dayOfWeek && s.weekNumber && survFieldWorkStart) { //generate date from week number and day of week
		// 				playDate = this.getSpotDateFromSchedule(s.dayOfWeek, s.weekNumber, survFieldWorkStart)
		// 			}
		// 			let duration = 0
		// 			if(s.spotDuration) duration = s.spotDuration
		// 			let cost = 0
		// 			if(s.spotRate) cost = s.spotRate
		// 			let spotClass = ''
		// 			if(s.spotClass) spotClass = s.spotClass
		// 			spotNumber++
		// 			let stationId = stnob.id.replace("g_", "")
		// 			str += "RFOUT,100,"+createDate+",Audology,"+survid+","+mktid+","+mktob.shortcode+","+spotNumber+","+playDate+","
		// 			str += s.qtrHrStartTime + "," //remove ":00" from end of time
		// 			str += duration+","+stationId+","+stnob.name+","+s.audioType+","+cost+","+spotClass+","
		// 			str += this.demoData.demoName+","+this.demoData.demoName
		// 			str += this.demoData.summary.universeEstimate + ","
		// 			str += s.reach + "," + s.reach + ","
		// 			//cume growing reach, and cume exclusive n+ reach - awaiting Ian G info
		// 			str += "\n"
		// 		}
		// 	}
		// 	let csvContent = "data:text/csv;charset=utf-8," + str
		// 	let encodedUri = encodeURI(csvContent)
		// 	let link = document.createElement("a")
		// 	link.setAttribute("href", encodedUri)
		// 	link.setAttribute("download", "RFOUT.csv")
		// 	document.body.appendChild(link) // Required for FF
		// 	link.click()
		// },
	},
	watch: {
		'store.returnedData': {
			handler() {
				this.selectFirstIfNull()
			}
		},
		viewType() {
			if(this.viewType === 'market' && !this.chosenMarketId) {
				if(this.demoData && this.demoData.marketSplits && this.demoData.marketSplits.length > 0) {
					this.chosenMarketId = this.demoData.marketSplits[0].marketId
				}
			}
		},
		marketCanSplitAudioTypes() {
			if(this.marketCanSplitAudioTypes === false) {
				this.chosenAudioType = 99
			}
		},
		exportDemoCount() {
			if(this.exportDemoCount === this.exportDemoCountTotal && this.exportDemoCountTotal > 0) {
				this.doExcelWrite()
			}
		}
	},
	mounted() {
		let urlParams = new URLSearchParams(window.location.search)
		if(urlParams.has('view')) {
			let v = urlParams.get('view')
			if(v === 'summary-table') this.viewType = 'summaryTable'
		}
		this.selectFirstIfNull()
	}
}
</script>

<style scoped>
.hidden {
	display: none;
}
.topsection {
	display: flex;
	gap: 50px;
	padding: 0 0 25px 0;
}
.nplusSection {
	display: flex;
	gap: 50px;
	padding: 0 0 25px 0;
}
.nplusChartHold {
	min-width: 450px;
}
.minor-heading {
	margin: 8px 16px 5px;
	font-size: 13px;
	font-weight: bold;
}
.halvedsections {
	display: flex;
	gap: 50px;
	padding: 15px 0;
}
.share-text {
	color: var(--bluedark);
	font-size: 12px;
	cursor: pointer;
	display: inline-block;
	margin-left: 10px;
}
.share-text:hover span {
	text-decoration: underline;
}
.share-text i {
	margin-right: 3px;
}
.rfMarketPieHold {
	width: 100%;
	max-width: 700px;
	height: 340px;
	margin-bottom: 20px;
}
.table-holder {
	width: 100%;
	overflow-x: auto;
}
.selectbox {
	margin-bottom: 10px;
}

@media screen and (max-width: 1399px) {
	.halvedsections {
		flex-direction: column;
	}
}

@media screen and (max-width: 1250px) {
	.topsection {
		display: block;
	}
	.topsection .overview-table {
		width: 100%;
	}
	.nplusSection {
		display: flex;
		gap: 50px;
		padding: 40px 0 25px 0;
	}
}

@media screen and (max-width: 1190px) {
	.weeksplithold {
		margin-top: 30px;
	}
}
@media screen and (max-width: 899px) {
	.topsection .overview-table,
	.topsection .nplusTable {
		width: 50%
	}
	.nplusSection {
		display: block;
	}
	.nplusChartHold {
		height: 300px;
		margin-top: 40px;
	}
	.nplusChartHold {
		min-width: unset;
	}
}
@media screen and (max-width: 640px) {
	.selectbox-hold {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.selectbox {
		margin-right: 0;
		width: calc(50% - 10px);
	}
	.warnbox {
		width: 100%;
	}
	.topsection .nplusTable {
		width: 100%;
	}
}
@media screen and (max-width: 450px) {
	.selectbox {
		width: 100%;
	}
}
</style>